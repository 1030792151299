import React, { useState, useEffect } from 'react';
import { Button, Modal, Box, Divider } from '@mui/material';
import { BUSCARPROPIETARIO } from '../../utils/apiUrls';
import TablaPropietarios from './TablaPropietarios';
import NuevoPropietario from './NuevoPropietario';

const SeleccionarPropietario = ({ open, setOpenModal, inmueble, actualiza, setActualiza }) => {
  const [openModal, setOpenNuevoPropietario] = useState(false);
  const [propietario, setPropietario] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(BUSCARPROPIETARIO + '/' + inmueble.id_propietario);
        if (!response.ok) {
          const errorData = await response.json();
          throw new Error(errorData.message || 'Error en la solicitud');
        }
        const data = await response.json();
        setPropietario(data.data[0]);
        if (data.data[0]) {
          setOpenNuevoPropietario(true);
        }
      } catch (error) {
        console.error('Error de solicitud:', error.message);
      }
    };
    fetchData();
  }, [inmueble.id_propietario]);

  const handleAgregarPropietario = () => {
    setOpenNuevoPropietario(true);
  };

  const handleClose = () => {
    setOpenModal(false);
  };

  return (
    <>
      <Modal open={open} onClose={handleClose}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: propietario ? 'auto' : '1050px',
            height: 'auto',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 3,
            borderRadius: 2,
          }}
          style={{ maxHeight: '97%', overflow: 'auto' }}
        >
          {propietario ? (
            <>
              <NuevoPropietario
                open={openModal}
                setOpenModal={setOpenNuevoPropietario}
                inmueble={inmueble}
                openOld={open}
                setOpenOld={setOpenModal}
                actualiza={actualiza}
                setActualiza={setActualiza}
                modificar={true}
                setPropietarios={setPropietario}
              />
            </>
          ) : (
            <>
              <div className="w-full text-xl font-bold text-center py-3 text-white bg-blue-600 rounded-t-md">
                PROPIETARIOS
              </div>

              <div className="text-center my-4">
                <span className="text-red-600 mr-4">
                  Si el PROPIETARIO que buscas no está en la tabla de abajo, se recomienda
                </span>
                <Button variant="outlined" color="success" onClick={handleAgregarPropietario}>
                  AGREGAR NUEVO PROPIETARIO
                </Button>
              </div>

              <TablaPropietarios
                inmueble={inmueble}
                actualiza={actualiza}
                setActualiza={setActualiza}
                closeModal={handleClose}
              />

              <Divider className="my-4" />

              <NuevoPropietario
                open={openModal}
                setOpenModal={setOpenNuevoPropietario}
                inmueble={inmueble}
                openOld={open}
                setOpenOld={setOpenModal}
                modificar={false}
                actualiza={actualiza}
                setActualiza={setActualiza}
              />
            </>
          )}
        </Box>
      </Modal>
    </>
  );
};

export default SeleccionarPropietario;
