import React, { useState, useEffect } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import { BUSCARINQUILINOS, ADD_SELECT_INQUILINO } from '../../utils/apiUrls';
import { styled } from '@mui/material/styles';
import { tableCellClasses } from '@mui/material/TableCell';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import Swal from 'sweetalert2';

const BlueTable = ({ inmueble, actualiza, setActualiza, closeModal }) => {
  const [rows, setData] = useState([]);
  const [sortBy, setSortBy] = useState(null);
  const [sortOrder, setSortOrder] = useState('asc');
  const [searchValue, setSearchValue] = useState('');
  const [searchResults, setSearchResults] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(BUSCARINQUILINOS);
        if (!response.ok) {
          const errorData = await response.json();
          throw new Error(errorData.message || 'Error en la solicitud');
        }
        const data = await response.json();
        setData(data.data);
      } catch (error) {
        console.error('Error de solicitud:', error.message);
      }
    };
    fetchData();
  }, [actualiza]);

  const handleAgregarInquilino = (row) => {
    const requestBody = { row: row, id_propiedad: inmueble.id };

    fetch(ADD_SELECT_INQUILINO, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(requestBody),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Error en la solicitud POST');
        }
        return response.json();
      })
      .then((data) => {
        setActualiza(!actualiza);
        closeModal();
        Swal.fire({
          title: 'Seleccion Exitosa',
          text: 'El inquilino se ha SELECCIONADO CORRECTAMENTE.',
          icon: 'success',
        });
      })
      .catch((error) => {
        console.error('Error en la solicitud POST:', error);
        closeModal();
        Swal.fire({
          title: 'Ups...',
          text: 'Tuvimos un error al procesar el cambio. Aguarde unos segundos y vuelva a intentar...',
          icon: 'error',
        });
      });
  };

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      color: theme.palette.common.white,
      backgroundColor: '#0a3e9e',
      fontWeight: 'bold',
      cursor: 'pointer',
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: '#f0f3ff',
    },
    '&:hover': {
      backgroundColor: '#e8d8c7',
      transition: 'background-color 0.3s',
    },
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));

  const handleSort = (column) => {
    if (sortBy === column) {
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    } else {
      setSortBy(column);
      setSortOrder('asc');
    }
  };

  const handleSearchChange = (event) => {
    const searchValue = event.target.value.toLowerCase();
    setSearchValue(searchValue);

    if (searchValue === '') {
      setSearchResults([]);
    } else {
      const filteredResults = rows.filter((row) => {
        const nombre = row.nombre?.toString().toLowerCase();
        const apellido = row.apellido?.toString().toLowerCase();
        const dni = row.dni?.toString().toLowerCase();

        return nombre?.includes(searchValue) || apellido?.includes(searchValue) || dni?.includes(searchValue);
      });
      setSearchResults(filteredResults);
    }
  };

  const renderRows = () => {
    const dataToRender = searchValue === '' ? rows : searchResults;
    return dataToRender.map((row) => (
      <StyledTableRow key={row.id}>
        <StyledTableCell align="center">{row.nombre}</StyledTableCell>
        <StyledTableCell align="center">{row.apellido}</StyledTableCell>
        <StyledTableCell align="center">{row.dni}</StyledTableCell>
        <StyledTableCell align="center">
          <IconButton aria-label="seleccionar inquilino" onClick={() => handleAgregarInquilino(row)}>
            <HowToRegIcon color="primary" />
          </IconButton>
        </StyledTableCell>
      </StyledTableRow>
    ));
  };

  return (
    <div className="flex-col w-full">
      <div className="my-4 mx-4">
        <TextField
          label="Buscar"
          variant="outlined"
          size="small"
          value={searchValue}
          onChange={handleSearchChange}
          className="w-full"
        />
      </div>
      <div className="w-full" style={{ height: '400px', overflowY: 'auto' }}>
        <TableContainer component={Paper}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                <StyledTableCell align="center" onClick={() => handleSort('nombre')}>
                  Nombre
                </StyledTableCell>
                <StyledTableCell align="center" onClick={() => handleSort('apellido')}>
                  Apellido
                </StyledTableCell>
                <StyledTableCell align="center" onClick={() => handleSort('dni')}>
                  DNI
                </StyledTableCell>
                <StyledTableCell align="center">Seleccionar</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>{renderRows()}</TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
};

export default BlueTable;
