import React, { useState } from 'react';
import { Button, TextField, Alert } from '@mui/material';
import logo from '../assets/images/logo.png';
import {LOGIN_URL} from '../utils/apiUrls'

const Login = ({onLogin }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showAlert, setShowAlert] = useState(false);
  const [msjAlert, setMsjAlert] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const handleLogin = async (e) => {
    e.preventDefault();

    if (!email || !password) {
      setShowAlert(true);
      return;
    }

    setIsLoading(true);

    try {
      // Realizar la solicitud POST al backend para el inicio de sesión
      const response = await fetch(LOGIN_URL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, password }),
      });

      const data = await response.json();

      if (response.ok && data.success) {
        // Si el inicio de sesión fue exitoso, redireccionamos al dashboard.
        localStorage.removeItem('token'); // Elimina el token de localStorage
        localStorage.setItem('token', data.token);
        onLogin(data.token);
      } else {
        // Si hubo un error en las credenciales, mostramos un mensaje de error.
        console.log('Error en las credenciales:', data.error);
        setMsjAlert(data.error)
        setShowAlert(true);
      }

      setIsLoading(false);
    } catch (error) {
      console.error('Error al autenticar al usuario', error);
      setIsLoading(false);
      setMsjAlert(error)
      setShowAlert(true);
    }
  };

  return (
      <div className="flex justify-center items-center h-screen bg-blue-50 ">
        <div className="w-full max-w-md bg-white rounded-lg shadow-md p-8 sm:w-11/12">
          <div className="flex justify-center mb-6">
            <a href="/" title="Ir a la página principal">
              <img src={logo} alt="Logo de la inmobiliaria" className="w-16 h-16" />
            </a>
          </div>
          <h2 className="text-2xl font-semibold mb-4 text-center">Iniciar sesión</h2>
          {showAlert && (
            <Alert severity="warning" onClose={() => setShowAlert(false)} className="mb-4">
             {msjAlert}
            </Alert>
          )}
          <form onSubmit={handleLogin}>
            <div className="mb-4">
              <TextField
                label="Email"
                variant="outlined"
                fullWidth
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>
            <div className="mb-6">
              <TextField
                label="Contraseña"
                type="password"
                variant="outlined"
                fullWidth
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </div>
            <div className="flex justify-center">
              <Button variant="contained" color="primary" type="submit" disabled={isLoading}>
                {isLoading ? 'Cargando...' : 'Ingresar'}
              </Button>
            </div>
          </form>
        </div>
      </div>
  );
};

export default Login;
