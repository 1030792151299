import React, { useState, useEffect } from "react";
import {
  BUSCAR_INMUEBLES_EDIFICIOS,
  BUSCAR_EXPENSAS_INDIVIDUAL,
  CARGAR_GASTO_INDIVIDUAL,
  ELIMINAR_GASTO_INDIVIDUAL,
  CARGAR_INMUEBLE,
  ELIMINAR_INMUEBLE,
  GENERAR_PDF,
} from "../../utils/apiUrls";
import TablaAgregarInmuebles from "./TablaAgregarInmuebles";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateField } from "@mui/x-date-pickers/DateField";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs"; // Importa dayjs
import TextField from "@mui/material/TextField";

const Inmuebles = ({
  isOpen,
  setIsModalOpen,
  id_edificio,
  setId_Edificio,
  fecha,
  setFecha,
}) => {
  const [inmuebles, setInmuebles] = useState([]);
  const [selectedInmueble, setSelectedInmueble] = useState(null);
  const [gastos, setGastos] = useState([]);
  const [monto, setMonto] = useState("");
  const [descripcion, setDescripcion] = useState("");
  const [direccion, setDireccion] = useState("");
  const [altura, setAltura] = useState("");
  const [piso, setPiso] = useState("");
  const [unidad, setUnidad] = useState("");
  const [openAgregarInmuebles, setOpenAgregarInmuebles] = useState(false);

  const handleClose = () => {
    setId_Edificio(0);
    setIsModalOpen(false);
  };

  const handleInmuebleClick = (inmueble) => {
    setSelectedInmueble(inmueble);
    fetchGastos(inmueble.id);
  };
  

  //aca
  useEffect(() => {
    if (selectedInmueble) {
      
      fetchGastos(selectedInmueble.id);
    }
  }, [fecha]);

  const fetchGastos = async (id_inmueble) => {
    console.log(id_inmueble)
    try {
      const response = await fetch(BUSCAR_EXPENSAS_INDIVIDUAL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          id: id_inmueble,
          fechaSeleccionada: fecha,
        }),
      });

      const data = await response.json();
      setGastos(data.data);
    } catch (error) {
      console.error("Error fetching gastos", error);
    }
  };

  const handleGastoSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(CARGAR_GASTO_INDIVIDUAL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          id_inmueble: selectedInmueble.id,
          monto,
          descripcion,
          fechaSeleccionada: fecha,
        }),
      });
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      setMonto("");
      setDescripcion("");
      fetchGastos(selectedInmueble.id);
    } catch (error) {
      console.error("Error adding gasto", error);
    }
  };

  const handleGastoDelete = async (id_gasto) => {
    try {
      const response = await fetch(`${ELIMINAR_GASTO_INDIVIDUAL}/${id_gasto}`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      setGastos(gastos.filter((gasto) => gasto.id !== id_gasto));
    } catch (error) {
      console.error("Error deleting gasto", error);
    }
  };

  const handleAgregarInmueble = () => {
    setOpenAgregarInmuebles(true);
  };

  const handleInmuebleDelete = async (id_inmueble) => {
    try {
      const response = await fetch(`${ELIMINAR_INMUEBLE}/${id_inmueble}`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      setInmuebles(inmuebles.filter((inmueble) => inmueble.id !== id_inmueble));
      if (selectedInmueble && selectedInmueble.id === id_inmueble) {
        setSelectedInmueble(null);
        setGastos([]);
      }
    } catch (error) {
      console.error("Error deleting inmueble", error);
    }
  };

  const handleImprimirPDF = async (inmueble) => {
    try {
      const response = await fetch(`${GENERAR_PDF}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          id: id_edificio,
          departamento: inmueble.id,
          fechaSeleccionada: fecha,
        }),
      });
  
      if (response.ok) {
        const blob = await response.blob();
        const url = URL.createObjectURL(blob);
        let mes = new Date(fecha).getMonth() + 1
        if (mes < 10) {
          mes = '0' + mes
        }
        let anio = new Date(fecha).getFullYear()
        // Generar el nombre del archivo dinámicamente
        const fileName = `${mes}-${anio} ${inmueble.direccion} ${inmueble.altura} ${inmueble.piso}-${inmueble.unidad}.pdf`.replace(/\//g, "-");
  
        // Crear un enlace temporal para descargar el archivo
        const a = document.createElement("a");
        a.href = url;
  
        // Asignar el nombre deseado al archivo descargado
        a.download = fileName;
  
        // Simular clic para iniciar la descarga
        document.body.appendChild(a);
        a.click();
  
        // Eliminar el enlace del DOM
        document.body.removeChild(a);
      } else {
        console.error("Error al generar el PDF");
      }
    } catch (error) {
      console.error("Error al generar el PDF", error);
    }
  };
  

  useEffect(() => {
    const fetchBuildings = async () => {
      try {
        const response = await fetch(BUSCAR_INMUEBLES_EDIFICIOS, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            id_edificio,
          }),
        });
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        setInmuebles(data.data); // Asume que la respuesta es un array de inmuebles
      } catch (error) {
        console.error("Error fetching buildings", error);
      }
    };

    fetchBuildings();
  }, [id_edificio, openAgregarInmuebles, fecha]);

  return (
    <div
      style={{ height: "calc(100vh - 150px)" }}
      className="inset-0 min-w-full bg-opacity-10 flex justify-center pt-1 items-center z-50"
    >
      <div
        style={{ height: "calc(100vh - 180px)" }}
        className="flex-col w-full max-w-6xl mx-4 rounded-lg p-6 bg-white mt-4"
      >
        <div className="min-w-full -mt-20 mb-10 end-3 ">
          <div className=" flex text-end items-end end-0">
            <div className="mr-2 mx-auto mb-2 font-bold">MES Y AÑO: </div>
            <LocalizationProvider size="small" dateAdapter={AdapterDayjs}>
              <DateField
                format="MM/YYYY"
                size="small"
                sx={{ width: "160px" }}
                value={fecha} // Usa fecha convertida a dayjs
                onChange={(date) => setFecha(dayjs(date))}
                renderInput={(params) => (
                  <TextField {...params} variant="standard" size="small" />
                )}
              />
            </LocalizationProvider>
          </div>
        </div>
        <div className="flex w-full">
          <div className="w-1/2 pr-4">
            <div className="flex">
              <h2 className="text-2xl text-center mx-auto font-semibold mb-6 text-gray-800">
                Lista de Inmuebles
              </h2>
              <div className="w-[200px]">
                <div
                  onClick={handleAgregarInmueble}
                  className="bg-blue-500 hover:bg-blue-400 text-white font-bold p-2 w-fit rounded cursor-pointer"
                >
                  + Agregar Inmueble
                </div>
              </div>
            </div>
            <div
              style={{ height: "calc(100vh - 350px)" }}
              className="overflow-x-auto"
            >
              {openAgregarInmuebles ? (
                <TablaAgregarInmuebles
                  id_edificio={id_edificio}
                  setIsModalOpen={setOpenAgregarInmuebles}
                />
              ) : (
                <table className="min-w-full bg-white">
                  <thead>
                    <tr>
                      <th className="py-2 px-4 border-b min-w-[180px]">
                        Dirección
                      </th>
                      <th className="py-2 px-4 border-b">Piso</th>
                      <th className="py-2 px-4 border-b">Dpto</th>
                      <th className="py-2 px-4 border-b">Expensa</th>
                      <th className="py-2 px-4 border-b">Eliminar</th>
                    </tr>
                  </thead>
                  <tbody>
                    {inmuebles.map((inmueble) => (
                      <tr
                        key={inmueble.id}
                        className={`cursor-pointer text-sm ${
                          selectedInmueble &&
                          selectedInmueble.id === inmueble.id
                            ? "bg-blue-100"
                            : ""
                        }`}
                        onClick={() => handleInmuebleClick(inmueble)}
                      >
                        <td className="py-2 px-4 border-b text-start text-sm">
                          {inmueble.direccion + " " + inmueble.altura}
                        </td>
                        <td className="py-2 px-4 border-b">{inmueble.piso}</td>
                        <td className="py-2 px-4 border-b">
                          {inmueble.unidad}
                        </td>
                        <td className="py-2 px-4 border-b">
                          <button
                            onClick={() => {
                              handleImprimirPDF(inmueble);
                            }}
                            className="bg-green-500 hover:bg-green-700 text-white font-bold py-1 px-2 rounded focus:outline-none focus:shadow-outline"
                          >
                            <CloudDownloadIcon />
                          </button>
                        </td>
                        <td className="py-2 px-4 border-b">
                          <button
                            onClick={(e) => {
                              e.stopPropagation();
                              handleInmuebleDelete(inmueble.id);
                            }}
                            className="bg-red-500 hover:bg-red-700 text-white font-bold py-1 px-2 rounded focus:outline-none focus:shadow-outline"
                          >
                            Eliminar
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}
            </div>
          </div>
          <div className="w-1/2 pl-4">
            {selectedInmueble && (
              <div>
                <h2 className="text-2xl font-semibold mb-6 text-gray-800">
                  Agregar Gastos al Inmueble
                </h2>
                <form onSubmit={handleGastoSubmit} className="mb-4">
                  <div className="flex">
                    <div className="mb-4 mx-auto max-w-[100px]">
                      <label className="block text-gray-700 text-sm font-bold mb-2">
                        Monto
                      </label>
                      <input
                        type="number"
                        value={monto}
                        onChange={(e) => setMonto(e.target.value)}
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        required
                      />
                    </div>
                    <div className="mb-4 mx-auto">
                      <label className="block text-gray-700 text-sm font-bold mb-2">
                        Descripción
                      </label>
                      <input
                        type="text"
                        value={descripcion}
                        onChange={(e) => setDescripcion(e.target.value)}
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        required
                      />
                    </div>
                    <button
                      type="submit"
                      className="bg-blue-500 hover:bg-blue-700 text-white font-bold h-10 mt-6 px-4 rounded focus:outline-none focus:shadow-outline"
                    >
                      Agregar Gasto
                    </button>
                  </div>
                </form>
                <h3 className="text-xl font-semibold mb-4 text-gray-800">
                  Lista de Gastos
                </h3>
                <div
                  style={{ height: "calc(100vh - 490px)" }}
                  className="overflow-x-auto"
                >
                  <table className="min-w-full bg-white">
                    <thead>
                      <tr>
                        <th className="py-2 px-4 border-b text-start">Descripción</th>
                        <th className="py-2 px-4 border-b ">Monto</th>
                        <th className="py-2 px-4 border-b">Acciones</th>
                      </tr>
                    </thead>
                    <tbody>
                      {gastos && gastos.map((gasto) => (
                        <tr key={gasto.id} className="text-sm">
                          <td className="py-2 px-4 border-b text-start">
                            {gasto.descripcion}
                          </td>
                          <td className="py-2 px-4 border-b">{gasto.monto}</td>
                          <td className="py-2 px-4 border-b">
                            <button
                              onClick={() => handleGastoDelete(gasto.id)}
                              className="bg-red-500 hover:bg-red-700 text-white font-bold py-1 px-2 rounded focus:outline-none focus:shadow-outline"
                            >
                              Eliminar
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="w-full flex mt-10">
          <button
            onClick={handleClose}
            className="bg-red-500 mx-auto px-4 hover:bg-red-700 text-white font-bold py-2 rounded focus:outline-none focus:shadow-outline"
          >
            CANCELAR
          </button>
        </div>
      </div>
    </div>
  );
};

export default Inmuebles;
