export const calcularIntereses = (fechaSeleccionada, inmueble, gastos, interes, diaDelMes, setAbonaInteres, setMontoIntereses, setMontoInteresesSinFormatear, setContratoVencido, setFaltanMenosDe60Dias, fechaFinContrato) => {
    const fechaActual = new Date();
    const fechaSel = new Date(fechaSeleccionada);

    let totalGasto = 0;

    // Calcular total de gastos
    if (gastos) {
        gastos.forEach((gasto) => {
            totalGasto += parseFloat(gasto.monto);
        });
    }

    // Si es de un mes anterior
    if (fechaSel.getMonth() < fechaActual.getMonth() || fechaSel.getFullYear() < fechaActual.getFullYear()) {
        const diasMesPasado = new Date(fechaSel.getFullYear(), fechaSel.getMonth() + 1, 0).getDate();
        let totalDias = diasMesPasado + diaDelMes;

        const montoInteresesCalculado = (((Number(inmueble.alquiler_precio) + Number(totalGasto)) * interes) / 100) * totalDias;
        setAbonaInteres(true);
        setMontoIntereses(montoInteresesCalculado);
        setMontoInteresesSinFormatear(montoInteresesCalculado);

    } else if (diaDelMes > 10) {
        // Si es del mismo mes y pasa el día 10
        const montoInteresesCalculado = (((Number(inmueble.alquiler_precio) + Number(totalGasto)) * interes) / 100) * diaDelMes;
        setAbonaInteres(true);
        setMontoIntereses(montoInteresesCalculado);
        setMontoInteresesSinFormatear(montoInteresesCalculado);
    }

    // Verificar si el contrato está vencido
    const fechaFin = new Date(fechaFinContrato);
    if (fechaFin < fechaActual) {
        setContratoVencido(true);
    } else {
        const diferenciaDias = Math.ceil((fechaFin - fechaActual) / (1000 * 60 * 60 * 24));
        setFaltanMenosDe60Dias(diferenciaDias <= 60);
    }
};
