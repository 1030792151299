import React from "react";
import Navar from "../utils/Navar";
import TitleNavar from "../components/dashboard/Title";
import Index from "../components/home/Index";
import { useStyles } from '../utils/funciones';

const Home = ({ setIsAuthenticated }) => {
  const classes = useStyles();

  return (
    <div className="bg-gray-100  h-screen flex">
      <Navar setIsAuthenticated={setIsAuthenticated} />
      <div className="w-full text-center flex-col mx-auto mt-32 -ml-20  h-full" style={{maxHeight: "calc(100vh - 130px)"}} >
        <div className={classes.card +'h-full'} style={{maxHeight: "calc(100vh + 160px)"}}>
          <div className="p-2 -mt-10 min-h-full h-full">
          <TitleNavar title={"HOME"} />
          <Index />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
