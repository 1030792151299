import React, { useState, useEffect } from 'react';
import { Button, Modal, Box } from '@mui/material';
import PaidOutlinedIcon from '@mui/icons-material/PaidOutlined';
import Divider from '@mui/material/Divider';
import Chip from '@mui/material/Chip';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import MapsHomeWorkRoundedIcon from '@mui/icons-material/MapsHomeWorkRounded';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import { Tooltip } from '@mui/material';
import ArrowCircleUpIcon from '@mui/icons-material/ArrowCircleUp';
import ArrowCircleDownIcon from '@mui/icons-material/ArrowCircleDown';
import { formatearMonedaARS, formatearFechaISO8601, sumarImportes } from '../../utils/funciones';
import MyDocument from './PdfPagos';
import PdfDescarga from './PdfDescarga';
import { PDFDownloadLink } from '@react-pdf/renderer';
import {POST_REGISTER_PAYMENT, BUSCARSERVICIOSSELECCIONADOS} from '../../utils/apiUrls'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs'; // Importa dayjs
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import TextField from '@mui/material/TextField';
import { DateField } from '@mui/x-date-pickers/DateField';
import Switch from '@mui/material/Switch';
import { useStyles } from '../../utils/funciones';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import { BUSCAR_MONTO_INDIVIDUAL } from '../../utils/apiUrls';
import Detalles from './Detalles'
import { calcularIntereses } from './calcularIntereses'; // Ajusta la ruta según la ubicación de tu archivo

const ModalPagos = ({ open, setOpenModal, inmueble,  setActualizarTabla, actualizarTabla, fechaSeleccionada}) => {
    const [intereses, setIntereses] = useState(0);
    let final = sumarImportes(inmueble, intereses);
    const [confirmaPago, setConfirmaPago] = useState(false);
    const [gastos, setGastos] = useState(null); // Estado para almacenar la gastos encontrada
    const [gastos2, setGastos2] = useState(null); // Estado para almacenar la gastos encontrada
    const [totalGastos, setTotalGastos] = useState(0);
    const [fecha, setFecha] = useState(fechaSeleccionada );
    const [actualizar, setActualizar] = useState(false)
    const [abonaInteres, setAbonaInteres] = useState(false)
    const [montoIntereses, setMontoIntereses] = useState(0)
    const [montoInteresesSinFormatear, setMontoInteresesSinFormatear] = useState(0)
    const [contratoVencido, setContratoVencido] = useState(false)
    const [faltanMenosDe60Dias, setFaltanMenosDe60Dias] = useState(0)
    const [numOperacion, setNumOperacion] = useState(null)
    const [descargaPdf, setDescargaPdf] = useState(false)
    const classes = useStyles();
    const fechaHoy = new Date();
    const diaDelMes = fechaHoy.getDate(); // Obtener el día del mes (1-31)
    let fechaFinContrato
    const fechaActual = dayjs();
    const fechaInicioContrato = dayjs(inmueble.fecha_inicio);
    const [interes, setInteres] = useState(0);
    const [isProcessing, setIsProcessing] = useState(false); // Estado para controlar si se está procesando el pago
    switch (inmueble.duracion_contrato) {
        case 1:
             fechaFinContrato = fechaInicioContrato.add(1, 'year');
            break;
        case 2:
             fechaFinContrato = fechaInicioContrato.add(2, 'year');
            break;
        // Agrega más casos según sea necesario para manejar otras duraciones de contrato
        default:
             fechaFinContrato = fechaInicioContrato.add(3, 'year');
            break;
    }
    useEffect(() => {
        setInteres(inmueble.interes_mora_alquiler)
    }, []); 

    useEffect(() => {
        const cargarExpensa = async () => {
            try {
                const response = await fetch(BUSCAR_MONTO_INDIVIDUAL, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        id_inmueble: inmueble.id,  // ID del inmueble recibido en el body
                        fecha: fecha,  // La fecha seleccionada
                    }),
                });
    
                if (response.ok) {
                    const data = await response.json();
                    // Verificar que data.data sea un array antes de intentar combinarlo
                    if (Array.isArray(data.data)) {
                        setGastos(prevGastos => [...(prevGastos || []), ...data.data]);
                    } else if (data.data) {
                        // Si no es un array pero tiene algún valor, agregarlo de manera individual
                        setGastos(prevGastos => [...(prevGastos || []), data.data]);
                    } else {
                        console.error('No se encontraron gastos.');
                    }
                } else {
                    console.error('Error al cargar la expensa:', response.status);
                }
            } catch (error) {
                console.error('Error en la solicitud:', error);
            }
        };
    
        // Llamar a la función cargarExpensa solo si hay un inmueble seleccionado
        if (inmueble && inmueble.id) {
            cargarExpensa();
        }
    }, [inmueble.id, fecha]); // Ejecutar cuando cambian el inmueble o la fecha
    
    

    useEffect(() => {
        calcularIntereses(
            fechaSeleccionada, 
            inmueble, 
            gastos, 
            interes, 
            diaDelMes, 
            setAbonaInteres, 
            setMontoIntereses, 
            setMontoInteresesSinFormatear, 
            setContratoVencido, 
            setFaltanMenosDe60Dias,
            fechaFinContrato
        );
    }, [gastos, interes]); 

    function agregarGasto(servicio, monto) {
        gastos.push({ servicio, monto });
    }

    const handlePdf = () => {
        setConfirmaPago(true);
        setDescargaPdf(!descargaPdf)
    }

    const handlePagar = async () => {
            // Deshabilitar el botón para evitar múltiples clics
    if (isProcessing) return; // Evitar múltiples clics
    setIsProcessing(true); // Marcar como en proceso
    
      // Datos del pago a enviar al servidor
    agregarGasto('Alquiler', inmueble.alquiler_precio);
    if (abonaInteres === true) {
        agregarGasto('Intereses', montoIntereses);
    }
    
      const pago = {
        inmuebleId: inmueble.id, // Suponiendo que tengas un ID único para cada inmueble
        monto: inmueble.alquiler_precio, // Monto del alquiler
        fecha: new Date().toISOString(), // Fecha actual en formato ISO
        gastos,
        intereses: montoIntereses,
        abonaInteres: abonaInteres,
        mesAbono: fecha
    };

    
    try {
        const response = await fetch(POST_REGISTER_PAYMENT, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(pago),
        });

        if (response.ok) {
            const responseData = await response.json();
            setNumOperacion(responseData.nuevoID);
            setActualizarTabla(!actualizarTabla);
            setConfirmaPago(true);
        } else {
            console.error('Error al registrar el pago');
        }
    } catch (error) {
        console.error('Error en la solicitud:', error);
    } finally {
        setIsProcessing(false); // Habilitar el botón nuevamente cuando termine el proceso
    }
    };
    const handleFecha = (date) => {
        // Convertir el objeto Date a dayjs antes de actualizar el estado
        const newDate = dayjs(date);
        if (newDate.isValid()) {
            setFecha(newDate);
            setActualizar(!actualizar);
        }
    };
    useEffect(() => {
        let total = 0;
        // Sumar los montos de los gastos
        if (gastos && Array.isArray(gastos)) {
            gastos.forEach(gasto => {
                total += parseFloat(gasto.monto);
            });
            if (gastos2) {
                total += parseFloat(gastos2.monto)
            }
            total += parseFloat(inmueble.alquiler_precio);
            if (abonaInteres) {
                total += parseFloat(montoInteresesSinFormatear);
            }
            setTotalGastos(parseFloat(total));
        }
    }, [gastos, actualizar, abonaInteres, montoInteresesSinFormatear, inmueble.alquiler_precio]);

    const handleClose = () => {
        setOpenModal(false);
    };

    const handleAbonaIntereses = () =>{
        setAbonaInteres(!abonaInteres)
    }
    const handleModificaInteres = (e) =>{
        setInteres(e)
    }
    
    useEffect(() => {
      const buscarGastos = async () => {
        try {
          const response = await fetch(BUSCARSERVICIOSSELECCIONADOS, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
                body: JSON.stringify({ id_inmueble: inmueble.id, fecha:fecha }),
          });
          if (response.ok) {
            const data = await response.json();
            console.log(data)
            setGastos(data);
          } else {
            console.error('Error al buscar la gastos:', response.status);
            setGastos(null); 

          }
        } catch (error) {
          console.error('Error en la solicitud:', error);
        }
      };
       buscarGastos();
    }, [actualizar]);

    const tooltipContent = (
        <div className='flex-col p-2'>
            <div className='text-base pb-2'>
                <ArrowCircleUpIcon /> Inicio Contrato: {formatearFechaISO8601(inmueble.fecha_inicio)}
            </div>
            <hr className='border-t border-white w-full my-1' /> {/* Línea blanca */}
            <div className='text-base'>
                <ArrowCircleDownIcon /> Fin Contrato: {formatearFechaISO8601(inmueble.fecha_fin_contrato)}
            </div>
        </div>
    );

    return (
        <div className='min-w-full'>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-title"
                aria-describedby="modal-description"
            >
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: confirmaPago ? 800: 550,
                        height: confirmaPago ? 'h-full': 'h-full' ,
                        bgcolor: 'background.paper',
                        boxShadow: 24,
                        p: 4,
                    }}
                >
                    {confirmaPago ? (
                        descargaPdf? 
                        <>
                            <div className=' max-h-fit'>
                                <div className={classes.card}>
                                    <div className='w-full p-1 mb-6 bg-blue-900'> </div>
                                    <div className='font-semibold text-3xl text-center text-blue-700'>¡EL PDF ESTA LISTO PARA DESCARGARSE!</div>
                                    <div className='font-semibold pt-6 pl-10 text-center'>Para comenzar la descarga haga click en Descargar PDF.</div>
                                    <div className='w-full text-center'>
                                        <div className=' my-10 mx-auto  text-center bg-blue-500 rounded-xl p-4 font-black text-white  max-w-fit'>
                                            <PDFDownloadLink document={<PdfDescarga mesAbono={fecha} numOperacion={numOperacion} montoIntereses={montoIntereses} abonaInteres={abonaInteres} totalGastos={formatearMonedaARS(totalGastos)} inmueble={inmueble} gastos={gastos}/>} fileName={inmueble.direccion + " "+ inmueble.altura + " " + inmueble.piso + "-" +  inmueble.unidad + ".pdf"}  >
                                                {({ blob, url, loading, error }) => (
                                                loading ? 
                                                'Cargando documento...' : 
                                                <>
                                                <div className='flex'>
                                                    <CloudDownloadIcon />
                                                    <div className='pl-2 '>Descargar PDF</div>
                                                </div>

                                                </>)}
                                            </PDFDownloadLink>
                                        </div>
                                    </div>
                                    <div className='w-full p-1  bg-blue-900'> </div>
                                </div>
                            </div>
                        </>
                        :
                        <div className=' max-h-fit'>
                            <div className={classes.card}>
                                <div className='w-full p-1 mb-6 bg-blue-900'> </div>
                                <div className='font-semibold text-3xl text-center text-blue-700'>¡EL PAGO SE REGISTRO CORRECTAMENTE!</div>
                                <div className='font-semibold pt-6 pl-10 text-center'>Para descargar el comprobante haga click en el siguiente link.</div>
                                <div className='w-full text-center'>
                                    <div className=' my-10 mx-auto  text-center bg-blue-500 rounded-xl p-4 font-black text-white  max-w-fit'>
                                        <PDFDownloadLink document={<MyDocument mesAbono={fecha} numOperacion={numOperacion} montoIntereses={montoIntereses} abonaInteres={abonaInteres} totalGastos={formatearMonedaARS(totalGastos)} inmueble={inmueble} gastos={gastos}/>} fileName={inmueble.direccion + " "+ inmueble.altura + " " + inmueble.piso + "-" +  inmueble.unidad + ".pdf"}  >
                                            {({ blob, url, loading, error }) => (
                                            loading ? 
                                            'Cargando documento...' : 
                                            <>
                                            <div className='flex'>
                                                <CloudDownloadIcon />
                                                <div className='pl-2 '>Descargar PDF</div>
                                            </div>

                                            </>)}
                                        </PDFDownloadLink>
                                    </div>
                                </div>
                                <div className='w-full p-1  bg-blue-900'> </div>
                            </div>
                        </div>
 
                    ) : (
                        <>
                            <Divider />
                            
                            <div className='flex mt-4'>
                                <h2 className=' font-bold text-xl pb-4 w-full mx-auto flex text-center' id="modal-title">
                                    <div className='mx-auto flex'>
                                        <div className='pr-2'>
                                            <MapsHomeWorkRoundedIcon color='primary' />
                                        </div>
                                        <div className=''>
                                            {inmueble.direccion + " " + inmueble.altura + " - " + inmueble.piso + " " + inmueble.unidad}
                                        </div>
                                    </div>
                                </h2>
                            </div>
                            <div className='py-2'>
                                
                                {faltanMenosDe60Dias && !contratoVencido ? (
                                    <Tooltip title={tooltipContent} enterTouchDelay={0} className='pb-8'>
                                        <Stack sx={{ width: '100%' }} spacing={2}>
                                            <Alert severity="error">El contrato está por vencer</Alert>
                                        </Stack>
                                    </Tooltip>
                                ) : null}
                                {contratoVencido  ? (
                                    <Tooltip title={tooltipContent} enterTouchDelay={0} className='pb-8'>
                                        <Stack sx={{ width: '100%' }} spacing={2}>
                                            <Alert severity="error">El contrato esta vencido</Alert>
                                        </Stack>
                                    </Tooltip>
                                ) : null}
                            </div>
                            <div className='text-center flex min-w-full items-center'>
                                <div className='flex mx-auto  -mt-6 mb-2 ' >
                                    <p className='mt-2 font-bold pr-2'>MES Y AÑO: </p>
                                    <LocalizationProvider size="small"  dateAdapter={AdapterDayjs}>
                                    <DateField 
                                        format="MM/YYYY" 
                                        size="small"
                                        sx={{ width: '160px' }}
                                        value={fecha} // Usa fecha convertida a dayjs
                                        onChange={(date) => handleFecha(date)}
                                        renderInput={(params) => (
                                        <TextField {...params} variant="standard" size="small" />
                                        )}
                                    />
                                    </LocalizationProvider>
                                </div>
                            </div>
                            <div className='w-full   items-end text-end aling'>
                                {abonaInteres ?
                                    <div className=' flex'> 
                                    <div className='w-full'></div>

                                        <TextField 
                                            onChange={(e) => handleModificaInteres(e.target.value)} 
                                            value={interes} 
                                            size='small' 
                                            sx={{ width: 110 }} 
                                        />
                                        <div className=' pt-2  font-semibold pl-1'>%</div>
                                        <Switch sx={{marginLeft:-1}}  checked={abonaInteres} onChange={handleAbonaIntereses}/>
                                        <div className='pt-2 pr-3  font-semibold'> Intereses  </div>
                                    </div>
                                :
                                <div className=' '><Switch checked={abonaInteres} onChange={handleAbonaIntereses}/>Intereses  </div>
                               }

                            </div>
                         

                            <Divider>
                                <Chip color="primary" label="DETALLES" />
                            </Divider>
                            
                            <Detalles 
                                inmueble={inmueble} 
                                gastos={gastos} 
                                gastos2={gastos2} 
                                abonaInteres={abonaInteres} 
                                montoIntereses={montoIntereses} 
                                formatearMonedaARS={formatearMonedaARS} 
                            />
                            <Divider>
                                <Chip color="primary" label="TOTAL" />
                            </Divider>
                            <div className='my-4 flex p-1 font-semibold text-xl'><><PaidOutlinedIcon style={{ color: 'green' }} /> <p id="modal-description" className='pl-2 w-full'>Total a abonar: </p> <p className=' text-end'>{formatearMonedaARS(parseFloat(totalGastos))}</p></></div>


                            <div className='w-full flex text-center mb-4'>
                                <div className=' w-1/3 mx-auto'>
                                    {contratoVencido ? null : 
                                        <>
                                        <div  className=' text-white flex  text-center'>
                                            <div onClick={handlePagar} className='mx-auto rounded-md cursor-pointer w-40 bg-green-700 text-center font-semibold hover:bg-green-800 p-2'>
                                                <CheckIcon sx={{marginRight:1}} />
                                                ABONAR
                                            </div>
                                        </div>
                                       </>

                                    }
                                </div>
                                <div className='w-1/3 mx-auto'>
                                    <>
                                        <div  className=' text-white flex  text-center'>
                                            <div onClick={handlePdf} className='mx-auto rounded-md cursor-pointer w-24 bg-blue-700 text-center font-semibold hover:bg-blue-800 p-2'>
                                                <CloudDownloadIcon sx={{marginRight:1}} />
                                                PDF
                                            </div>
                                        </div>
                                    </>
                                </div>
                                <div className='w-1/3 mx-auto'>
                                    <>
                                        <div  className=' text-white flex  text-center'>
                                            <div onClick={handleClose} className='mx-auto rounded-md cursor-pointer w-40 bg-red-700 text-center font-semibold hover:bg-red-800 p-2'>
                                                <ClearIcon sx={{marginRight:1}} />
                                                CANCELAR
                                            </div>
                                        </div>
                                    </>
                                </div>
                            </div>
                            <Divider />
                        </>
                    )}
                </Box>
            </Modal>
        </div>
    );
};

export default ModalPagos;
