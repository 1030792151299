const DetallesPago = ({ inmueble, gastos, gastos2, abonaInteres, montoIntereses, formatearMonedaARS }) => {
    return (
        <div className='my-4'>
            {/* Alquiler */}
            <div className='flex text-center'>
                <div className='w-1/2 text-center'>ALQUILER</div>
                <div className='w-1/2 text-end mr-4'>
                    {formatearMonedaARS(parseFloat(inmueble.alquiler_precio))}
                </div>
            </div>

            {/* Gastos */}
            {gastos && Array.isArray(gastos) && gastos.map((gasto, index) => (
                <div key={gasto.id_servicio} className='flex'>
                    <div className={`w-1/2 text-center ${typeof gasto.monto === 'string' && gasto.monto.startsWith('-') ? 'text-blue-500 font-semibold' : ''}`}>
                        {gasto.servicio.toUpperCase()}
                    </div>
                    <div className={`w-1/2 text-end mr-4`}>
                        <p className={`text-end ${typeof gasto.monto === 'string' && gasto.monto.startsWith('-') ? 'text-blue-500 font-semibold' : ''}`}>
                            {formatearMonedaARS(parseFloat(gasto.monto))}
                        </p>
                    </div>
                </div>
            ))}

            {/* Gastos 2 */}
            {gastos2 && (
                <div className='flex'>
                    <div className={`w-1/2 text-center ${typeof gastos2.monto === 'string' && gastos2.monto.startsWith('-') ? 'text-blue-500 font-semibold' : ''}`}>
                        {gastos2.servicio.toUpperCase()}
                    </div>
                    <div className='w-1/2 text-end mr-4'>
                        <p className={`text-end ${typeof gastos2.monto === 'string' && gastos2.monto.startsWith('-') ? 'text-blue-500 font-semibold' : ''}`}>
                            {formatearMonedaARS(parseFloat(gastos2.monto))}
                        </p>
                    </div>
                </div>
            )}

            {/* Interés Alquiler */}
            {abonaInteres && (
                <div className='flex'>
                    <div className='w-1/2 text-center'>INTERES ALQUILER</div>
                    <div className='w-1/2 text-end mr-4'>
                        {formatearMonedaARS(montoIntereses)}
                    </div>
                </div>
            )}
        </div>
    );
};

export default DetallesPago;
