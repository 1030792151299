import React, { useState, useEffect } from 'react';
import { Button, Modal, Box, Divider } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { BUSCARINQUILINO } from '../../utils/apiUrls';
import Swal from 'sweetalert2';
import TablaInquilino from './TablaInquilinos';
import NuevoInquilino from './NuevoInquilino';

const SeleccionarInquilino = ({ open, setOpenModal, inmueble, actualiza, setActualiza }) => {
  const [openModal, setOpenNuevoInquilino] = useState(false);
  const [inquilino, setInquilino] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(BUSCARINQUILINO + '/' + inmueble.id);
        if (!response.ok) {
          const errorData = await response.json();
          throw new Error(errorData.message || 'Error en la solicitud');
        }
        const data = await response.json();
        setInquilino(data.data[0]);
        if (data.data[0]) {
          setOpenNuevoInquilino(true);
        }
      } catch (error) {
        console.error('Error de solicitud:', error.message);
      }
    };
    fetchData();
  }, [inmueble.id]);

  const handleAgregarInquilino = () => {
    setOpenNuevoInquilino(true);
  };

  const handleClose = () => {
    setOpenModal(false);
  };

  return (
    <>
      <Modal open={open} onClose={handleClose}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: inquilino ? 'auto' : '1050px',
            height: 'auto',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 3,
            borderRadius: 2,
          }}
          style={{ maxHeight: '97%', overflow: 'auto' }}
        >
          {inquilino ? (
            <NuevoInquilino
              open={openModal}
              setOpenModal={setOpenNuevoInquilino}
              inmueble={inmueble}
              openOld={open}
              setOpenOld={setOpenModal}
              cambiarinquilino={true}
              inquilino={inquilino}
              setInquilino={setInquilino}
            />
          ) : (
            <>
              <div className="w-full text-xl font-bold text-center py-3 text-white bg-blue-600 rounded-t-md">
                INQUILINOS
              </div>

              <div className="flex justify-end my-4 mr-4">
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<AddIcon />}
                  onClick={handleAgregarInquilino}
                >
                  AGREGAR NUEVO INQUILINO
                </Button>
              </div>

              <TablaInquilino
                inmueble={inmueble}
                actualiza={actualiza}
                setActualiza={setActualiza}
                closeModal={handleClose}
              />

              <Divider className="my-4" />

              <NuevoInquilino
                open={openModal}
                setOpenModal={setOpenNuevoInquilino}
                inmueble={inmueble}
                cambiarinquilino={false}
                openOld={open}
                setOpenOld={setOpenModal}
                actualiza={actualiza}
                setActualiza={setActualiza}
              />
            </>
          )}
        </Box>
      </Modal>
    </>
  );
};

export default SeleccionarInquilino;
