import React, { useState, useEffect } from 'react';
import { Routes, Route, useNavigate } from 'react-router-dom';
import Login from './pages/Login';
import Pagos from './pages/Pagos';
import Propiedades from './pages/Propiedades';
import Rendiciones from './pages/Rendiciones';
import Index from './pages/Index';
import './index.css';
import Propietarios from './pages/Propietarios';
import Edificios from './pages/Edificios';
import Home from './pages/Home';
import HomeInquilino from './pages/HomeInquilino';

const App = () => {
  const navigate = useNavigate();
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [decodedToken, setDecodedToken] = useState(null);

  const decodeToken = (token) => {
    try {
      const payload = token.split('.')[1];
      return JSON.parse(atob(payload));
    } catch (error) {
      console.error('Error al decodificar el token:', error);

      return null;
    }
  };

  const handleLogin = (token) => {
    localStorage.setItem('token', token);
    const decoded = decodeToken(token);
    setDecodedToken(decoded);
    setIsAuthenticated(true);

    if (decoded && decoded.results && decoded.results[0].tipo_usuario === 'ADMIN') {
      navigate('/home');
    } else if (decoded && decoded.results && decoded.results[0].tipo_usuario === 'INQUILINO') {
      navigate('/inquilino');
    }
  };

  const handleLogout = () => {
    localStorage.removeItem('token');
    setDecodedToken(null);
    setIsAuthenticated(false);
    navigate('/login');
  };

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      const decoded = decodeToken(token);
      setDecodedToken(decoded);
      setIsAuthenticated(true);
    }
  }, []);

  return (
    <Routes>
      <Route path="/" element={<Index onLogin={handleLogin} />} />
      <Route path="/login" element={<Login onLogin={handleLogin} />} />
      {isAuthenticated && decodedToken && decodedToken.results && (
        <>
          {decodedToken.results[0].tipo_usuario === 'ADMIN' ? (
            <>
              <Route path="/propietarios" element={<Propietarios />} />
              <Route path="/pagos" element={<Pagos />} />
              <Route path="/propiedades" element={<Propiedades />} />
              <Route path="/rendiciones" element={<Rendiciones />} />
              <Route path="/edificios" element={<Edificios />} />
              <Route path="/home" element={<Home onLogout={handleLogout} />} />
              <Route path="/" element={<Index />} />
            </>
          ) : (
            <Route path="/inquilino" element={<HomeInquilino onLogout={handleLogout} />} />
          )}
        </>
      )}
    </Routes>
  );
};

export default App;
