import React, { useState, useEffect } from 'react';
import { Button, Modal, Box, Divider, Input, MenuItem, Select, Stack } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateField } from '@mui/x-date-pickers/DateField';
import { BUSCARPROPIETARIO, ADD_PROPIETARIO, UPDATE_PROPIETARIO } from '../../utils/apiUrls';
import Swal from 'sweetalert2';
import dayjs from 'dayjs';

const NuevoPropietario = ({ open, setOpenModal, inmueble, openOld, setOpenOld, actualiza, setActualiza, modificar, setPropietarios }) => {
  const [propietario, setPropietario] = useState(null);
  const [formulario, setFormulario] = useState({
    direccion: null,
    fecha_nacimiento: null,
    nombre: null,
    apellido: null,
    dni: null,
    telefono: null,
    email: null,
    genero: null,
    id: null,
    id_propiedad: inmueble.id,
    id_propietario: inmueble.id_propietario,
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(BUSCARPROPIETARIO + '/' + inmueble.id_propietario);
        if (!response.ok) {
          const errorData = await response.json();
          throw new Error(errorData.message || 'Error en la solicitud');
        }
        const data = await response.json();
        setPropietario(data.data[0]);
        setFormulario({
          ...formulario,
          fecha_nacimiento: data.data[0].fecha_nacimiento,
          direccion: data.data[0].direccion,
          nombre: data.data[0].nombre,
          apellido: data.data[0].apellido,
          dni: data.data[0].dni,
          telefono: data.data[0].telefono,
          email: data.data[0].email,
          genero: data.data[0].genero,
          id: data.data[0].id_propietario,
        });
      } catch (error) {
        console.error('Error de solicitud:', error.message);
      }
    };

    if (modificar) {
      fetchData();
    }
  }, [actualiza]);

  const handleSave = (event) => {
    const { name, value } = event.target;
    setFormulario({ ...formulario, [name]: value });
  };

  const handleSaveGenero = (event) => {
    const { value } = event.target;
    setFormulario({ ...formulario, genero: value });
  };

  const handleClose = () => {
    setOpenModal(false);
    setOpenOld(!openOld);
  };

  const handleCargarInmuebleExitoso = () => {
    setActualiza(!actualiza);
    setOpenOld(!openOld);
    handleClose();
    Swal.fire({
      title: propietario ? 'Actualización exitosa' : 'Carga exitosa',
      text: `El propietario se ha ${propietario ? 'ACTUALIZADO' : 'CREADO'} correctamente.`,
      icon: 'success',
    });
  };

  const handleGuardar = (accion) => {
    formulario.fecha_nacimiento = dayjs(formulario.fecha_nacimiento).format('YYYY-MM-DD');
    const url = accion === 'Actualizar' ? UPDATE_PROPIETARIO : ADD_PROPIETARIO;
    fetch(url, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(formulario),
    })
      .then((response) => {
        if (response.ok) {
          handleCargarInmuebleExitoso();
        } else {
          handleError();
        }
      })
      .catch(handleError);
  };

  const handleError = () => {
    setOpenModal(!open);
    handleClose();
    Swal.fire({
      title: 'Ups...',
      text: 'Tuvimos un error al procesar el cambio. Aguarde unos segundos y vuelva a intentar...',
      icon: 'error',
    });
  };

  const handleFechaNacimiento = (date) => {
    setFormulario({ ...formulario, fecha_nacimiento: dayjs(date).format('YYYY-MM-DD') });
  };

  const handleAgregarPropietario = () => {
    setOpenModal(false);
    setPropietarios(false);
  };

  return (
    <>
      <Modal open={open} onClose={handleClose}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '900px',
            height: 'auto',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 3,
            borderRadius: 2,
          }}
          style={{ maxHeight: '97%', overflow: 'auto' }}
        >
          {/* Título del modal */}
          <div className="w-full text-2xl font-bold text-center py-4 text-white bg-blue-600 rounded-t-md">
            PROPIETARIO
          </div>

          <div className="w-full text-lg font-bold text-center py-4 text-gray-700">
            {`${inmueble.direccion} ${inmueble.altura} - Piso: ${inmueble.piso} Unidad: ${inmueble.unidad}`}
          </div>

          {modificar && (
            <div className="my-4 text-center">
              <span className="text-red-600 mr-2">Si el propietario es incorrecto, puedes</span>
              <Button variant="contained" color="primary" onClick={handleAgregarPropietario}>
                CAMBIAR DE PROPIETARIO
              </Button>
            </div>
          )}

          <Divider className="my-4" />

          {/* Formulario */}
          <div className="grid grid-cols-2 gap-8">
            <div className="flex flex-col">
              <label className="font-semibold pb-2">Nombre</label>
              <Input
                placeholder="Nombre"
                value={formulario.nombre || ''}
                name="nombre"
                onChange={handleSave}
                fullWidth
                className="rounded-lg border-gray-300"
              />
            </div>

            <div className="flex flex-col">
              <label className="font-semibold pb-2">Apellido</label>
              <Input
                placeholder="Apellido"
                value={formulario.apellido || ''}
                name="apellido"
                onChange={handleSave}
                fullWidth
                className="rounded-lg border-gray-300"
              />
            </div>

            <div className="flex flex-col">
              <label className="font-semibold pb-2">DNI</label>
              <Input
                placeholder="DNI"
                value={formulario.dni || ''}
                name="dni"
                onChange={handleSave}
                fullWidth
                className="rounded-lg border-gray-300"
              />
            </div>

            <div className="flex flex-col">
              <label className="font-semibold pb-2">Fecha Nacimiento</label>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DateField
                  format="DD/MM/YYYY"
                  value={formulario.fecha_nacimiento ? dayjs(formulario.fecha_nacimiento) : null}
                  onChange={handleFechaNacimiento}
                  fullWidth
                  className="rounded-lg border-gray-300"
                />
              </LocalizationProvider>
            </div>

            <div className="flex flex-col">
              <label className="font-semibold pb-2">Dirección</label>
              <Input
                placeholder="Dirección"
                value={formulario.direccion || ''}
                name="direccion"
                onChange={handleSave}
                fullWidth
                className="rounded-lg border-gray-300"
              />
            </div>

            <div className="flex flex-col">
              <label className="font-semibold pb-2">Teléfono</label>
              <Input
                placeholder="Teléfono"
                value={formulario.telefono || ''}
                name="telefono"
                onChange={handleSave}
                fullWidth
                className="rounded-lg border-gray-300"
              />
            </div>

            <div className="flex flex-col">
              <label className="font-semibold pb-2">Email</label>
              <Input
                placeholder="Email"
                value={formulario.email || ''}
                name="email"
                onChange={handleSave}
                fullWidth
                className="rounded-lg border-gray-300"
              />
            </div>

            <div className="flex flex-col">
              <label className="font-semibold pb-2">Género</label>
              <Select
                value={formulario.genero }
                onChange={handleSaveGenero}
                name="genero"
                fullWidth
                className="rounded-lg border-gray-300"
              >
                <MenuItem value={0}>Masculino</MenuItem>
                <MenuItem value={1}>Femenino</MenuItem>
              </Select>
            </div>
          </div>

          <Divider className="my-4" />

          {/* Botones */}
          <div className="flex justify-center mt-6">
            <Stack spacing={2} direction="row">
              <Button variant="contained" color="success" onClick={() => handleGuardar(propietario ? 'Actualizar' : 'Guardar')}>
                {propietario ? 'Actualizar' : 'Guardar'}
              </Button>
              <Button variant="contained" color="error" onClick={handleClose}>
                Cancelar
              </Button>
            </Stack>
          </div>
        </Box>
      </Modal>
    </>
  );
};

export default NuevoPropietario;
