import React, { useState } from 'react';
import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  IconButton,
  CssBaseline,
  Box,
  Drawer,
  useMediaQuery,
} from '@mui/material';
import { Link, useLocation } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
import HouseIcon from '@mui/icons-material/House';
import PointOfSaleIcon from '@mui/icons-material/PointOfSale';
import BarChartIcon from '@mui/icons-material/BarChart';
import PersonRoundedIcon from '@mui/icons-material/PersonRounded';
import ApartmentIcon from '@mui/icons-material/Apartment';
import ChevronLeftIcon from '@mui/icons-material/ChevronRight';
import ChevronRightIcon from '@mui/icons-material/ChevronLeft';

const Navar = ({ setIsAuthenticated }) => {
  const location = useLocation();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isDrawerMini, setIsDrawerMini] = useState(false);
  const isMobile = useMediaQuery('(max-width:600px)');
  const navbarHeight = 0;

  const isActive = (path) => location.pathname === path;

  const handleLogout = () => {
    localStorage.removeItem('token');
  };

  const toggleDrawer = () => {
    setIsDrawerMini((prev) => !prev);
  };

  return (
    <>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
          <Toolbar>
            <IconButton color="inherit" aria-label="open drawer" onClick={() => setIsDrawerOpen(true)}>
              <MenuIcon />
            </IconButton>
            <Typography variant="h6" noWrap component="div">
              Inmobiliaria Chiappero
            </Typography>
            <Box sx={{ flexGrow: 1 }} />
            <Button color="inherit" component={Link} to="/login" onClick={handleLogout}>
              Salir
            </Button>
          </Toolbar>
        </AppBar>
        <Drawer
          variant={isMobile ? 'temporary' : 'permanent'}
          sx={{
            width: isDrawerMini ? 60 : 200,
            flexShrink: 0,
            '& .MuiDrawer-paper': {
              width: isDrawerMini ? 60 : 200,
              boxSizing: 'border-box',
              transition: 'width 0.3s ease-in-out',
              overflowX: 'hidden', // Evita el desplazamiento horizontal
              overflowY: 'hidden', // Evita el desplazamiento vertical
            },
          }}
          open={isDrawerOpen}
          onClose={() => setIsDrawerOpen(false)}
        >
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              transform: 'translateY(-50%)',
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              zIndex: 1,
               marginLeft: isDrawerMini ? '0px' : '90px' 
            }}
          >
            <IconButton onClick={toggleDrawer}>
              {!isDrawerMini ? <ChevronRightIcon /> : <ChevronLeftIcon />}
            </IconButton>
          </Box>
          <List
            sx={{
              background: 'rgb(186 230 253)',
              minHeight: `calc(100vh - ${navbarHeight}px)`,
              width: '100%',
              paddingTop: '80px',
              overflowY: 'auto', // Permite el desplazamiento vertical para el contenido de la lista
              borderRight: "2px degrade black"
            }}
          >
            <ListItem
              button
              component={Link}
              to="/home"
              sx={{
                height:'40px',
                backgroundColor: isActive('/home') ? 'rgb(37 99 235)' : 'transparent',
                color: isActive('/home') ? 'white' : 'black',
                fontWeight: isActive('/home') ? 'bold' : 'normal',
                transition: 'background-color 0.3s ease-in-out',
                '&:hover': {
                  backgroundColor: isActive('/home') ? 'rgb(29 78 216)' : 'rgb(37 99 235)',
                  color: 'white',
                },
              }}
            >
              <ListItemIcon>
                <HouseIcon sx={{ color: isActive('/home') ? 'white' : 'black' }} />
              </ListItemIcon>
              {!isDrawerMini && <ListItemText primary="HOME" />}
            </ListItem>
            <ListItem
              button
              component={Link}
              to="/propiedades"
              sx={{
                height:'40px',
                backgroundColor: isActive('/propiedades') ? 'rgb(37 99 235)' : 'transparent',
                color: isActive('/propiedades') ? 'white' : 'black',
                fontWeight: isActive('/propiedades') ? 'bold' : 'normal',
                transition: 'background-color 0.3s ease-in-out',
                '&:hover': {
                  backgroundColor: isActive('/propiedades') ? 'rgb(29 78 216)' : 'rgb(37 99 235)',
                  color: 'white',
                },
              }}
            >
              <ListItemIcon>
                <HouseIcon sx={{ color: isActive('/propiedades') ? 'white' : 'black' }} />
              </ListItemIcon>
              {!isDrawerMini && <ListItemText primary="PROPIEDADES" />}
            </ListItem>
            <ListItem
              button
              component={Link}
              to="/propietarios"
              sx={{
                height:'40px',
                backgroundColor: isActive('/propietarios') ? 'rgb(82 82 91)' : 'transparent',
                color: isActive('/propietarios') ? 'white' : 'rgb(82 82 91)',
                fontWeight: isActive('/propietarios') ? 'bold' : 'normal',
                transition: 'background-color 0.3s ease-in-out',
                '&:hover': {
                  backgroundColor: isActive('/propietarios') ? 'rgb(82 82 91)' : 'rgb(82 82 91)',
                  color: 'white',
                },
              }}
            >
              <ListItemIcon>
                <PersonRoundedIcon sx={{ color: isActive('/propietarios') ? 'white' : 'black' }} />
              </ListItemIcon>
              {!isDrawerMini && <ListItemText primary="PROPIETARIOS" />}
            </ListItem>
            <ListItem
              button
              component={Link}
              to="/pagos"
              sx={{
                height:'40px',
                backgroundColor: isActive('/pagos') ? '#e53e3e' : 'transparent',
                color: isActive('/pagos') ? 'white' : 'black',
                fontWeight: isActive('/pagos') ? 'bold' : 'normal',
                transition: 'background-color 0.3s ease-in-out',
                '&:hover': {
                  backgroundColor: isActive('/pagos') ? 'rgb(185 28 28)' : '#e53e3e',
                  color: 'white',
                },
              }}
            >
              <ListItemIcon>
                <PointOfSaleIcon sx={{ color: isActive('/pagos') ? 'white' : 'black' }} />
              </ListItemIcon>
              {!isDrawerMini && <ListItemText primary="PAGOS" />}
            </ListItem>
            <ListItem
              button
              component={Link}
              to="/rendiciones"
              sx={{
                height:'40px',
                backgroundColor: isActive('/rendiciones') ? '#4caf50' : 'transparent',
                color: isActive('/rendiciones') ? 'white' : 'black',
                fontWeight: isActive('/rendiciones') ? 'bold' : 'normal',
                transition: 'background-color 0.3s ease-in-out',
                '&:hover': {
                  backgroundColor: isActive('/rendiciones') ? '#388e3c' : '#388e3c',
                  color: 'white',
                },
              }}
            >
              <ListItemIcon>
                <BarChartIcon sx={{ color: isActive('/rendiciones') ? 'white' : 'black' }} />
              </ListItemIcon>
              {!isDrawerMini && <ListItemText primary="RENDICIONES" />}
            </ListItem>
            <ListItem
              button
              component={Link}
              to="/edificios"
              sx={{
                height:'40px',
                backgroundColor: isActive('/edificios') ? 'rgb(76 29 149)' : 'transparent',
                color: isActive('/edificios') ? 'white' : 'black',
                fontWeight: isActive('/edificios') ? 'bold' : 'normal',
                transition: 'background-color 0.3s ease-in-out',
                '&:hover': {
                  backgroundColor: isActive('/edificios') ? 'rgb(76 29 149)' : 'rgb(76 29 149)',
                  color: 'white',
                },
              }}
            >
              <ListItemIcon>
                <ApartmentIcon sx={{ color: isActive('/edificios') ? 'white' : 'black' }} />
              </ListItemIcon>
              {!isDrawerMini && <ListItemText primary="EDIFICIOS" />}
            </ListItem>
          </List>
        </Drawer>
        <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
          <Toolbar />
        </Box>
      </Box>
    </>
  );
};

export default Navar;
