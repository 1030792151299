import React from 'react';
import { Document, Page, Text, View, StyleSheet, Image } from '@react-pdf/renderer';
import logo from './../../assets/images/logo.png';
import facebook from './../../assets/images/facebook.png';
import instagram from './../../assets/images/instagram.png';
import firma from './../../assets/images/firma.png';
import { formatearFechaISO8601, formatearMonedaARS } from '../../utils/funciones';

const styles = StyleSheet.create({
  page: {
    flexDirection: 'row',
    backgroundColor: '#FFF',
    padding: 40,
  },
  section: {
    flexGrow: 1,
    textAlign: 'center',
  },
  logoContainer: {
    marginBottom: 20,
  },
  logo: {
    width: 75,
    height: 75,
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  firma: {
    width: 150, 
    height: 150, 
    marginLeft: 'auto',
    marginRight: 'auto',
    marginBottom:120
  },
  iconos: {
    height:'8px',
    width:'8px',
    position: 'relative',
    top: 2,
    left: 5, 
  },
  title: {
    fontSize: 22,
    fontWeight: 'bold',
    marginBottom: 10,
    color: '#333',
    borderBottom: '2pt solid #333',
    paddingBottom: 5,
    marginTop:10,
  },
  subtitle: {
    fontSize: 14,
    marginBottom: 20,
    color: '#666',
  },
  text: {
    fontSize: 12,
    marginBottom: 5,
    color: '#555',
  },
  footer: {
    position: 'absolute',
    bottom: 97,
    left: 0,
    right: 0,
    textAlign: 'center',
    fontSize: 10,
    color: '#999',
    marginTop: 20,
  },
  socialLinks: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    textAlign: 'center',
  },
  socialLinkstext: {
    fontSize: 8,
    marginTop: 5,
    color: '#666',
  },
  separator: {
    borderBottom: '1pt solid #999',
    marginBottom: 10,
  },
});

const fecha_pago = new Date();
const hora = fecha_pago.getHours();
const minutos = fecha_pago.getMinutes();
const hora_pago = hora + ":" + minutos;
let mes = fecha_pago.getMonth() + 1; 
if (mes < 10) {
  mes = '0' + mes
}
const anio = fecha_pago.getFullYear(); 
let dia = fecha_pago.getDate();
if (dia < 10) {
  dia = '0' + dia
}
const fecha_format = dia + "-"+ mes+ "-"+anio

function formatearMes(mes) {
  return mes < 10 ? '0' + mes : mes;
}

const MyDocument = ({inmueble, gastos, totalGastos, montoIntereses, abonaInteres, numOperacion, mesAbono}) => (
   <Document>
    <Page size="A4" style={styles.page}>
      <View style={styles.section}>
        <View style={styles.logoContainer}>
          <Image src={logo} style={styles.logo} />
        </View>
        <Text style={styles.subtitle}>Inmobiliaria Chiappero</Text>
        <Text style={styles.text}>
          <Text style={styles.text}>N° de comprobante de pago: {numOperacion ? numOperacion : gastos.length > 0 ? gastos[0].id : null}</Text>
          <Text style={styles.text}> // Fecha de registro: {gastos[0].fecha_pago ? formatearFechaISO8601(gastos[0].fecha_pago)  : fecha_format} - {gastos[0].hora_pago ? gastos[0].hora_pago : hora_pago} Hs.</Text>
        </Text>
        <Text style={styles.title}>Recibo de Alquiler</Text>
        <Text style={styles.text}>RECIBO POR CUENTA Y ORDEN DE TERCEROS</Text>
        <Text style={styles.text}>Inquilino/a: {inmueble.nombre + " " + inmueble.apellido + " Dni: " + inmueble.dni}</Text>
        <Text style={styles.text}>Inmueble: {inmueble.direccion + " " + inmueble.altura + " " + inmueble.piso + " " + inmueble.unidad}</Text>
        <Text style={styles.text}>
          Alquiler correspondiente al mes de {
            mesAbono ? mesAbono instanceof Date 
            ? formatearMes(mesAbono.getMonth() + 1)  +"-"+ mesAbono.getFullYear()  
            : formatearMes(new Date(mesAbono).getMonth() + 1)  +"-"+ new Date(mesAbono).getFullYear()  
            : 'Fecha no válida'
          }
        </Text>        
        {gastos && gastos.map((facturaItem, index) => (
          facturaItem.item === 'Alquiler' ?
            <Text key={index} style={{
              paddingVertical: 2,
              paddingRight: 10,
              fontSize: 12,
              marginBottom: 5,
              color: '#555',
            }}>
              Monto del Alquiler: {formatearMonedaARS(parseFloat(facturaItem.monto))}
            </Text>
          : null
        ))}

        {gastos.map((facturaItem, index) => (
          facturaItem.item !== 'Alquiler' || (facturaItem.item !== 'Alquiler' && !abonaInteres && facturaItem.item !== 'Intereses') ? 
          <View key={index} style={{ flexDirection: 'row', justifyContent: 'space-between', marginLeft: '170px', width: '151px' }}>
            <Text style={{
              paddingVertical: 2,
              paddingRight: 10,
              fontSize: 12,
              marginBottom: 5,
              color: '#555',
            }}>
              {facturaItem.item ? facturaItem.item : facturaItem.servicio}:
            </Text>
            <Text style={{
              padding: 2,
              fontSize: 12,
              marginBottom: 5,
              textAlign: 'end',
              color: '#555',
            }}>{formatearMonedaARS(parseFloat(facturaItem.monto))}</Text>
          </View> : null
        ))}

        <Text style={{ padding: 2, fontSize: 16, fontWeight:'black', marginBottom: 5 }}>
          TOTAL A ABONAR:  {totalGastos} 
        </Text>
        <View style={styles.socialLinks}>
          <Image src={firma} style={styles.firma} />
        </View>

        <Text style={styles.footer}>Este recibo confirma el pago total del alquiler del inmueble mencionado anteriormente y sirve como comprobante de la transacción. Para cualquier consulta o aclaración, no dude en comunicarse con nosotros.</Text>
        <View style={styles.socialLinks}>
          <View style={styles.separator} />
          <Text style={styles.socialLinkstext}>Para más información, contáctanos:</Text>
          <Text style={styles.socialLinkstext}>Horarios de Atención: Lunes a Viernes de 9:00 a 18:00 hs</Text>
          <Text style={styles.socialLinkstext}>Email: Inmobiliariachiappero@gmail.com</Text>
          <Text style={styles.socialLinkstext}>Teléfono: (03532) 67-8233</Text>
          <Text style={styles.socialLinkstext}>
            <Image src={facebook} style={styles.iconos} /> - Síguenos en Facebook: @Inmobiliariachiappero
          </Text>
          <Text style={styles.socialLinkstext}>
            <Image src={instagram} style={styles.iconos} /> - Síguenos en Instagram: @Inmobiliariachiappero
          </Text>
        </View>
      </View>
    </Page>
  </Document>
);

export default MyDocument;
