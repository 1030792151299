import React, { useState, useEffect } from "react";
import { Person, Group, Description, AddCard } from "@mui/icons-material";
import {
  BUSCAR_INFO_INMUEBLE,
  BUSCAR_SERVICIOS_INMUEBLE,
  BUSCAR_INQUILINO_INMUEBLE,
  BUSCAR_PROPIETARIO_INMUEBLE
} from "../../utils/apiUrls";
import { convertirFecha } from "../../utils/funciones";
import AgregarPropiedad from "./AgregarPropiedad"; // Ajusta la ruta según tu estructura de archivos

const PropertyCard = ({
  propiedad,
  onEditPropietario,
  onEditInquilino,
  onEditContrato,
  onEditServicios
}) => {
  const [data, setData] = useState(null);
  const [inquilino, setInquilino] = useState(null);
  const [propietario, setPropietario] = useState(null);
  const [openModal, setOpenModal] = useState(false);

  useEffect(
    () => {
      const fetchData = async () => {
        try {
          const response = await fetch(BUSCAR_INFO_INMUEBLE, {
            method: "POST",
            headers: {
              "Content-Type": "application/json"
            },
            body: JSON.stringify({ id_inmueble: propiedad.id })
          });
          if (!response.ok) {
            const errorData = await response.json();
            throw new Error(errorData.message || "Error en la solicitud");
          }
          const data = await response.json();
          setData(data);

          // BUSCO PROPIETARIO
          const propietario = await fetch(
            BUSCAR_PROPIETARIO_INMUEBLE + propiedad.id_propietario,
            {
              method: "GET",
              headers: {
                "Content-Type": "application/json"
              }
            }
          );
          if (!propietario.ok) {
            const errorData = await propietario.json();
            throw new Error(errorData.message || "Error en la solicitud");
          }
          const queryPropietario = await propietario.json();
          setPropietario(queryPropietario.data);
          // BUSCO INQUILINO

          const reqinquilino = await fetch(BUSCAR_INQUILINO_INMUEBLE, {
            method: "POST",
            headers: {
              "Content-Type": "application/json"
            },
            body: JSON.stringify({ id_inmueble: propiedad.id })
          });
          if (!reqinquilino.ok) {
            const errorData = await reqinquilino.json();
            throw new Error(errorData.message || "Error en la solicitud");
          }
          const inquilino = await reqinquilino.json();
          setInquilino(inquilino);
          //
          const response2 = await fetch(BUSCAR_SERVICIOS_INMUEBLE, {
            method: "POST",
            headers: {
              "Content-Type": "application/json"
            },
            body: JSON.stringify({ id_inmueble: propiedad.id })
          });
          if (!response2.ok) {
            const errorData = await response2.json();
            throw new Error(errorData.message || "Error en la solicitud");
          }
          const data2 = await response2.json();
          setData(data2);
        } catch (error) {
          console.error("Error de solicitud:", error.message);
        }
      };
      fetchData();
    },
    [propiedad.id]
  );
  console.log(propietario);

  return (
    <div
      style={{ maxHeight: "calc(100vh - 247px)" }}
      className="max-h-full bg-white shadow-lg rounded-xl overflow-hidden my-6 border border-gray-300 transition-shadow hover:shadow-xl duration-300"
    >
      <div className="px-8 py-4 h-full">
        <div className="flex justify-between items-center mb-6">
          <h2 className="text-3xl font-bold text-gray-900">
            {`${propiedad.direccion} ${propiedad.altura}, ${propiedad.piso} - ${propiedad.unidad}`}
          </h2>
          <button
            className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
            onClick={() => setOpenModal(true)}
          >            Editar Propiedad
          </button>
          <AgregarPropiedad
          open={openModal}
          setOpenModal={setOpenModal}
          inmueble={propiedad} // Pasa los datos actuales de la propiedad
          setActualiza={() => {}} // Ajusta según cómo manejas las actualizaciones globales
        />
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-10 -mt-2">
          {/* Inquilino */}
          {/* Contrato */}
          <div className="border-r-2 pr-6">
            <SectionHeader
              title="Contrato"
              icon={<Description className="text-yellow-600" />}
              onEdit={() => onEditContrato(propiedad)}
            />
            <div className="-mt-2">
              {propiedad.id_contrato
                ? <DetailsList
                    items={[
                      { label: "Estado", value: data ? "Activo" : "Inactivo" },
                      {
                        label: "Fecha de Inicio",
                        value: data
                          ? convertirFecha(data[0].fecha_inicio)
                          : "Cargando..."
                      },
                      {
                        label: "Duración",
                        value: data
                          ? `${data[0].duracion_contrato} Años`
                          : "Cargando..."
                      },
                      {
                        label: "Tipo de Aumento",
                        value: data ? data[0].indice_aumento : "Cargando..."
                      },
                      {
                        label: "Intervalo de Aumento",
                        value: data
                          ? `${data[0].tipo_aumento} Meses`
                          : "Cargando..."
                      }
                    ]}
                  />
                : <NoAssignmentMessage />}
            </div>
          </div>
          <div>
            <SectionHeader
              title="Inquilino"
              icon={<Group className="text-green-600" />}
              onEdit={() => onEditInquilino(propiedad)}
            />
            <div className="-mt-2">
              {inquilino
                ? <DetailsList
                    items={[
                      {
                        label: "Nombre",
                        value: data
                          ? `${inquilino[0].nombre} ${inquilino[0].apellido}`
                          : "Cargando..."
                      },
                      {
                        label: "Dirección",
                        value: data ? inquilino[0].direccion : "Cargando..."
                      },
                      {
                        label: "Teléfono",
                        value: data ? inquilino[0].telefono : "Cargando..."
                      },
                      {
                        label: "Email",
                        value: data ? inquilino[0].email : "Cargando..."
                      },
                      {
                        label: "DNI",
                        value: data ? inquilino[0].dni : "Cargando..."
                      }
                    ]}
                  />
                : <NoAssignmentMessage />}
            </div>
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-10 mt-6">
          {/* Propietario */}
          <div className="border-r-2 pr-6">
            <SectionHeader
              title="Propietario"
              icon={<Person className="text-blue-600" />}
              onEdit={() => onEditPropietario(propiedad)}
            />
            <div className="-mt-2">
              {propietario
                ? <DetailsList
                    items={[
                      {
                        label: "Nombre",
                        value: data
                          ? `${propietario[0].nombre} ${propietario[0]
                              .apellido}`
                          : "Cargando..."
                      },
                      {
                        label: "Teléfono",
                        value: data ? propietario[0].telefono : "Cargando..."
                      },
                      {
                        label: "Email",
                        value: data ? propietario[0].email : "Cargando..."
                      }
                    ]}
                  />
                : <NoAssignmentMessage />}
            </div>
          </div>

          {/* Servicios */}
          <div>
            <SectionHeader
              title="Servicios"
              icon={<AddCard className="text-purple-600" />}
              onEdit={() => onEditServicios(propiedad)}
            />
            <p className="text-gray-600">
              {propiedad.serviciosactivos ? "Activos" : "No activos"}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

const SectionHeader = ({ title, icon, onEdit }) =>
  <div className="flex justify-between items-center mb-6">
    <h3 className="text-xl font-semibold text-gray-800 flex items-center">
      {icon}
      <span className="ml-3">
        {title}
      </span>
    </h3>
    <button
      onClick={onEdit}
      className="bg-blue-500 hover:bg-blue-600 text-white py-2 px-4 rounded-full text-sm shadow-md transition-transform transform hover:scale-105"
    >
      Editar
    </button>
  </div>;

const DetailsList = ({ items }) =>
  <ul className="text-gray-700 space-y-3">
    {items.map((item, index) =>
      <li key={index}>
        <span className="font-semibold">{item.label}:</span>{" "}
        {item.value || "No disponible"}
      </li>
    )}
  </ul>;

const NoAssignmentMessage = () =>
  <p className="text-red-500 font-semibold">No asignado</p>;

export default PropertyCard;
