import React, { useState, useEffect } from "react";
import ContratosAumento from "./ContratosAumentan";
import ContratosVencer from "./ContratosVencer";
import Deudores from "./DeudoresMes";
const Index = ({
  open,
  setOpenModal,
  inmueble,
  setActualizarTabla,
  actualizarTabla,
  fechaSeleccionada
}) => {
  return (
    <div className="min-w-full mx-2 flex-col min-h-full">
      <div className="min-w-full flex">
        <div className="w-7/12 mx-2 h-full ">
          <ContratosAumento />
        </div>
        <div className="w-5/12 mx-2 flex-col min-h-full">
          <div className="max-h-fit">
            <ContratosVencer />
          </div>
          <div className="max-h-fit">
            <Deudores />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Index;
