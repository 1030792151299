import React, { useState, useEffect } from 'react';
import { Button, Modal, Box } from '@mui/material';
import Divider from '@mui/material/Divider';
import Input from '@mui/material/Input';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateField } from '@mui/x-date-pickers/DateField';
import { ADD_INQUILINO, UPDATE_INQUILINO, BUSCARINQUILINO } from '../../utils/apiUrls';
import Swal from 'sweetalert2';
import TextField from '@mui/material/TextField';
import dayjs from 'dayjs';
import AddIcon from '@mui/icons-material/Add';

const NuevoInquilino = ({ open, setOpenModal, inmueble, openOld, setOpenOld, actualiza, setActualiza, cambiarinquilino, inquilino, setInquilino, modificar }) => {
    const ariaLabel = { 'aria-label': 'description' };
    const [inquilinos, setInquilinos] = useState(null);
    const [formulario, setFormulario] = useState({
        direccion: null,
        fecha_nacimiento: null,
        nombre: null,
        apellido: null,
        dni: null,
        telefono: null,
        email: null,
        genero: null,
        id: null,
        id_propiedad: inmueble.id,
    });

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(BUSCARINQUILINO + '/' + inmueble.id);
                if (!response.ok) {
                    const errorData = await response.json();
                    throw new Error(errorData.message || 'Error en la solicitud');
                }
                const data = await response.json();
                setInquilinos(data.data[0]);
                setFormulario({
                    ...formulario,
                    fecha_nacimiento: data.data[0].fecha_nacimiento,
                    direccion: data.data[0].direccion,
                    nombre: data.data[0].nombre,
                    apellido: data.data[0].apellido,
                    dni: data.data[0].dni,
                    telefono: data.data[0].telefono,
                    email: data.data[0].email,
                    genero: data.data[0].genero,
                    id: data.data[0].id_inquilino,
                });
            } catch (error) {
                console.error('Error de solicitud:', error.message);
            }
        };
        if (cambiarinquilino) {
            fetchData();
        }
    }, [cambiarinquilino, inmueble.id]);

    const handleSave = (event) => {
        const { name, value } = event.target;
        setFormulario({ ...formulario, [name]: value });
    };

    const handleSaveGenero = (event) => {
        const { value } = event.target;
        setFormulario({ ...formulario, genero: value });

    };

    const handleClose = () => {
        setOpenModal(false);
        setOpenOld(!openOld);
    };

    const handleCargarInmuebleExitoso = () => {
        setActualiza(!actualiza);
        handleClose();
        Swal.fire({
            title: inquilinos ? 'Actualización exitosa' : 'Carga exitosa',
            text: `El inquilino se ha ${inquilinos ? 'ACTUALIZADO' : 'CREADO'} correctamente.`,
            icon: 'success',
        });
    };

    const handleGuardar = () => {
        formulario.fecha_nacimiento = dayjs(formulario.fecha_nacimiento).format('YYYY-MM-DD');
        fetch(cambiarinquilino ? UPDATE_INQUILINO : ADD_INQUILINO, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(formulario),
        })
            .then((response) => {
                if (response.ok) {
                    handleCargarInmuebleExitoso();
                } else {
                    setOpenModal(!open);
                    handleClose();
                    Swal.fire({
                        title: 'Ups...',
                        text: 'Tuvimos un error al procesar el cambio. Aguarde unos segundos y vuelva a intentar...',
                        icon: 'error',
                    });
                }
            })
            .catch((error) => {
                console.error('Error en la solicitud:', error);
            });
    };

    const handleFechaNacimiento = (date) => {
        setFormulario({ ...formulario, fecha_nacimiento: dayjs(date).format('YYYY-MM-DD') });
    };

    const handleAgregarInquilino = () => {
        setOpenModal(false);
        setInquilino(false);
    };

    return (
        <>
            <Modal open={open} onClose={handleClose}>
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: 900,
                        bgcolor: 'background.paper',
                        boxShadow: 24,
                        borderRadius: 2,
                        p: 4,
                        border: '2px solid #ede6db',
                    }}
                    style={{ maxHeight: '97%', overflow: 'auto' }}
                >
                    <div className="w-full text-2xl font-bold text-center py-4 text-white bg-blue-600 rounded-t-lg">
                        Inquilino
                    </div>

                    <div className="w-full text-xl font-bold text-center py-4 text-gray-700">
                        {inmueble.direccion + ' ' + inmueble.altura + ' - Piso: ' + inmueble.piso + ' Unidad: ' + inmueble.unidad}
                    </div>

                    {cambiarinquilino && (
                        <div className="text-center text-red-600 my-4">
                            <span className="mr-2">Si el inquilino es INCORRECTO, puedes</span>
                            <Button
                                variant="contained"
                                color="primary"
                                startIcon={<AddIcon />}
                                onClick={handleAgregarInquilino}
                            >
                                Cambiar de Inquilino
                            </Button>
                        </div>
                    )}

                    <Divider sx={{ my: 2 }} />

                    <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                        <div className="flex flex-col">
                            <label className="font-semibold pb-2">Nombre</label>
                            <Input
                                placeholder="Fernando"
                                value={formulario.nombre || ''}
                                name="nombre"
                                onChange={handleSave}
                                fullWidth
                                className="rounded-lg border-gray-300"
                            />
                        </div>
                        <div className="flex flex-col">
                            <label className="font-semibold pb-2">Apellido</label>
                            <Input
                                placeholder="Chiappero"
                                value={formulario.apellido || ''}
                                name="apellido"
                                onChange={handleSave}
                                fullWidth
                                className="rounded-lg border-gray-300"
                            />
                        </div>
                        <div className="flex flex-col">
                            <label className="font-semibold pb-2">DNI</label>
                            <Input
                                placeholder="36333333"
                                value={formulario.dni || ''}
                                name="dni"
                                onChange={handleSave}
                                fullWidth
                                className="rounded-lg border-gray-300"
                            />
                        </div>
                        <div className="flex flex-col">
                            <label className="font-semibold pb-2">Fecha Nacimiento</label>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DateField
                                    format="DD/MM/YYYY"
                                    value={formulario.fecha_nacimiento ? dayjs(formulario.fecha_nacimiento) : null}
                                    onChange={handleFechaNacimiento}
                                    fullWidth
                                    className="rounded-lg border-gray-300"
                                />
                            </LocalizationProvider>
                        </div>
                    </div>

                    <Divider sx={{ my: 2 }} />

                    <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                        <div className="flex flex-col">
                            <label className="font-semibold pb-2">Dirección</label>
                            <Input
                                placeholder="Diego Diaz 663"
                                value={formulario.direccion || ''}
                                name="direccion"
                                onChange={handleSave}
                                fullWidth
                                className="rounded-lg border-gray-300"
                            />
                        </div>
                        <div className="flex flex-col">
                            <label className="font-semibold pb-2">Teléfono</label>
                            <Input
                                placeholder="3513303030"
                                value={formulario.telefono || ''}
                                name="telefono"
                                onChange={handleSave}
                                fullWidth
                                className="rounded-lg border-gray-300"
                            />
                        </div>
                        <div className="flex flex-col">
                            <label className="font-semibold pb-2">Email</label>
                            <Input
                                placeholder="info@gmail.com.ar"
                                value={formulario.email || ''}
                                name="email"
                                onChange={handleSave}
                                fullWidth
                                className="rounded-lg border-gray-300"
                            />
                        </div>
                        <div className="flex flex-col">
                            <label className="font-semibold pb-2">Género</label>
                            <Select
                                value={formulario.genero }
                                onChange={handleSaveGenero}
                                fullWidth
                                className="rounded-lg border-gray-300"
                            >
                                <MenuItem value={0}>Masculino</MenuItem>
                                <MenuItem value={1}>Femenino</MenuItem>
                            </Select>
                        </div>
                    </div>

                    <Divider sx={{ my: 2 }} />

                    <div className="flex justify-center mt-6">
                        <Stack spacing={2} direction="row">
                            <Button variant="contained" color="success" onClick={handleGuardar}>
                                {inquilino ? 'Actualizar' : 'Guardar'}
                            </Button>
                            <Button variant="contained" color="error" onClick={handleClose}>
                                Cancelar
                            </Button>
                        </Stack>
                    </div>
                </Box>
            </Modal>
        </>
    );
};

export default NuevoInquilino;
