import React, { useState, useEffect } from "react";
import SentimentSatisfiedAltIcon from "@mui/icons-material/SentimentSatisfiedAlt";
import HomeIcon from "@mui/icons-material/Home";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { useNavigate } from 'react-router-dom';

const Home = ({ setIsAuthenticated }) => {
  // Simulación de datos
  const [reclamos, setReclamos] = useState([]);
  const [deudaAlquiler, setDeudaAlquiler] = useState(0);
  const [deudaExpensas, setDeudaExpensas] = useState(0);
  const [expand, setExpand] = useState(false);
  const [seleccionTC, setSeleccionTC] = useState('Alquileres')
  const navigate = useNavigate(); // Hook para navegar

  const handleLogout = () => {
    localStorage.removeItem('token'); // Elimina el token
    navigate('/login'); // Redirige a la página de login
  };

  const handleChangeSeleccionTc = (data) =>{ 
    setSeleccionTC(data)
  }
  
  // Cargar datos simulados
  useEffect(() => {
    // Reclamos simulados
    setReclamos([
      { id: 1, estado:'En curso',tipo: "Reparación", descripcion: "Problema con las cañerías", fecha: "2024-11-01" },
      { id: 2, estado:'Pendiente',tipo: "Mantenimiento", descripcion: "Falta de luz en pasillo", fecha: "2024-11-05" },
      { id: 3, estado:'Finalizado',tipo: "Otro", descripcion: "Consulta sobre contrato", fecha: "2024-11-10" },

    ]);
    // Deudas simuladas
  }, []);

  const handleExpand = () => {
    setExpand(!expand);
  };
  return (
    <div className="bg-gradient-to-r from-blue-100 to-indigo-200 min-h-screen flex flex-col">
      {/* Header */}
      <header className="bg-indigo-600 h-16 flex justify-between items-center px-4 sm:px-6 shadow-lg">
        <div className="text-white text-lg sm:text-xl font-bold">
          Chiappero Inmobiliaria
        </div>
        <button
          className="text-white bg-red-500 px-3 py-1 sm:px-4 sm:py-2 rounded-md shadow-md hover:bg-red-600 transition duration-200 text-sm sm:text-base"
          onClick={() => handleLogout()}
        >
          Salir
        </button>
      </header>

      {/* Content */}
      <main className="flex-1 p-4 sm:p-4 sm:pt-4">
        {/* Sección de info */}

        <div className="grid grid-cols-1 sm:grid-cols-1 gap-6 mb-4">
          <div className="bg-white shadow-lg rounded-lg p-4 text-center border-t-4 border-green-500">
            <div className="flex text-center">
              <div className="mx-auto flex">
                <HomeIcon />
                <h2 className="-mt-0.5 text-xl font-semibold text-gray-700 mb-1">
                  Padre Luis Monti 1871
                </h2>
              </div>
            </div>
            <p className="text-gray-600 flex">
              <span className="font-medium mx-auto">Piso: 1</span>
              <span className="font-medium mx-auto">Dpto: B</span>
            </p>
          </div>
        </div>
        {/* Sección de deudas */}
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-6 mb-8">
          <div
            className={`bg-white shadow-lg rounded-lg p-4 border-t-4 ${deudaAlquiler
              ? "border-red-500"
              : "border-green-500"}`}
          >
            {deudaAlquiler
              ? <div>
                  <h2 className="text-xl font-semibold text-gray-700 mb-4 ">
                    Alquiler
                  </h2>
                  <p className="text-gray-600">
                    <span className="font-medium">Pendiente:</span> ${deudaAlquiler.toLocaleString()}
                  </p>
                </div>
              : <div>
                  <h2 className="text-xl font-semibold text-gray-700 mb-4 text-center">
                    Alquiler
                  </h2>
                  <p className="text-gray-600 flex-col ">
                    <div className="text-center p-2 -mt-4 text-green-600">
                      <SentimentSatisfiedAltIcon />
                    </div>
                    <div className="font-medium mb-1 text-center">
                      No registra Deuda
                    </div>
                    <div className="my-2 pb-1 font-medium text-sm text-center border-b-2">
                      Ultimo pago{" "}
                    </div>
                    <div className="font-medium text-sm text-center">
                      Fecha y hora:{" "}
                    </div>
                    
                    <div className="font-medium text-sm text-center ">
                      09/10/2024 07:45{" "}
                    </div>
                    <div className="font-medium text-sm text-center mt-2">
                      Monto:{" "}
                    </div>
                    
                    <div className="font-medium text-sm text-center">
                      $ 85.400,00{" "}
                    </div>
                  </p>
                </div>}
          </div>
          <div className="bg-white shadow-lg rounded-lg p-4 border-t-4 border-yellow-500">
            <h2 className="text-xl font-semibold text-gray-700 mb-4 text-center">
              Expensas
            </h2>
            <p className="text-gray-600">
              {deudaExpensas > 0
                ? <span className="font-medium">
                    Pendiente: ${deudaExpensas.toLocaleString()}
                  </span>
                : <div>
                    <div className="text-center p-2 -mt-4 text-green-600">
                      <SentimentSatisfiedAltIcon />
                    </div>
                    <div className="font-medium mb-1 text-center">
                      No registra Deuda
                    </div>
                    <div className="my-2 pb-1 font-medium text-sm text-center border-b-2">
                      Ultimo pago{" "}
                    </div>
                    <div className="font-medium text-sm text-center">
                      Fecha y hora:{" "}
                    </div>
                    <div className="font-medium text-sm text-center">
                      09/10/2024 07:45{" "}
                    </div>
                    <div className="font-medium text-sm text-center mt-2">
                      Monto:{" "}
                    </div>
                    
                    <div className="font-medium text-sm text-center">
                      $ 8.700,00{" "}
                    </div>
                  </div>}
            </p>
          </div>
        </div>
        <div
          className={`bg-white shadow-lg mb-4 rounded-lg p-4 ${reclamos.length > 0
            ? "border-t-4 border-red-500"
            : "border-t-4 border-green-500"}`}
        >
          <h2 className="text-xl font-semibold text-gray-700 mb-4 flex justify-between items-center">
            Mis Comprobantes
            </h2>

          <div className="flex">
            <div className={`mx-auto border-b-2 ${ seleccionTC === 'Alquileres' ? 'border-blue-400 text-blue-600 font-semibold': 'border-gray-400 font-normal text-gray-600'}`} onClick={() => handleChangeSeleccionTc('Alquileres')}>Alquileres</div>
            <div className={`mx-auto border-b-2 ${ seleccionTC === 'Expensas' ? 'border-blue-400 text-blue-600 font-semibold': 'border-gray-400 font-normal text-gray-600'}`} onClick={() => handleChangeSeleccionTc('Expensas')}>Expensas</div>
          </div>
          <table className="min-w-full bg-white mt-2">
            <thead>
              <tr className="  rounded-t-2xl text-gray-600">
                <th className="py-2 px-4">Fecha</th>
                <th className="py-2 px-4">Descargar</th>
              </tr>
            </thead>
            <tbody>
                <tr
                  className={`border-t cursor-pointer hover:bg-blue-300 hover:text-white hover:font-bold hover:text-base `} // Cambia el estilo si la fila está seleccionada
                  
                >
                  <td className="py-2 px-4">
                  11/2024 
                  </td>
                  <td className="py-2 px-4 text-center">
                    <button className="text-green-500">
                      Download
                    </button>
                  </td>
                </tr>
                <tr
                  className={`border-t cursor-pointer hover:bg-blue-300 hover:text-white hover:font-bold hover:text-base `} // Cambia el estilo si la fila está seleccionada
                  
                >
                  <td className="py-2 px-4">
                  10/2024 
                  </td>
                  <td className="py-2 px-4 text-center">
                    <button className="text-green-500">
                      Download
                    </button>
                  </td>
                </tr>
            </tbody>
          </table>
        </div>

        {/* Sección de reclamos */}
        <div
          className={`bg-white shadow-lg rounded-lg p-4 ${reclamos.length > 0
            ? "border-t-4 border-red-500"
            : "border-t-4 border-green-500"}`}
        >
          <h2 className="text-xl font-semibold text-gray-700 mb-4 flex justify-between items-center">
            {reclamos.length > 0
              ? <div className="flex items-center">
                  <div>Reclamos Abiertos</div>
                  <p className="ml-2 text-red-500">
                    ({reclamos.length})
                  </p>
                </div>
              : <div>Reclamos</div>}
            <div className="text-sm bg-green-600 text-white px-3 py-1.5 font-medium rounded-lg cursor-pointer hover:bg-green-700">
              + Reclamo
            </div>
          </h2>
          

          {reclamos.length > 0
            ? expand
              ? <ul className="space-y-4 max-h-60 overflow-y-auto transition-all duration-300">
                  {reclamos.map(reclamo =>
                    <li
                      key={reclamo.id}
                      className={`${reclamo.estado === 'En curso' ? 'bg-yellow-100' : reclamo.estado === 'Pendiente' ? 'bg-red-100': 'bg-green-100'} flex justify-between items-center  p-4 rounded-lg shadow-md`}
                    >
                      <div>
                        <p className="text-gray-800 font-medium">
                          Tipo: {reclamo.tipo}
                        </p>
                        <p className="text-gray-600">
                          {reclamo.descripcion}
                        </p>
                        <p className="text-sm text-gray-500">
                          Fecha: {new Date(reclamo.fecha).toLocaleDateString()}
                        </p>
                        <p className="text-sm text-gray-500">
                          Estado: {reclamo.estado}
                        </p>
                      </div>
                    </li>
                  )}
                  <div className="w-full flex text-center">
                    <div
                      className="text-center mx-auto rounded-full p-1 bg-gray-200 font-medium w-fit"
                      onClick={handleExpand}
                    >
                      <ExpandLessIcon />
                    </div>
                  </div>
                </ul>
              : <div className="w-full flex text-center">
                  <div
                    className="text-center mx-auto rounded-full p-1 bg-gray-200 font-medium w-fit"
                    onClick={handleExpand}
                  >
                    <ExpandMoreIcon />
                  </div>
                </div>
            : <p className="text-gray-600 ">Todo ok por aca!👍.</p>}
        </div>
        
      </main>
    </div>
  );
};

export default Home;
